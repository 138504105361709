import React from "react";
import { useBreadcrumb } from "gatsby-plugin-breadcrumb";

import CustomBreadcrumb from "../../customBreadcrumb/customBreadcrumb";
import ScrollButton from "../../button/scrollButton/scrollButton";

import "./headerBackground.scss";

export default function HeaderBackground({
  imagePath,
  title,
  text,
  location,
  crumbLabel,
}) {
  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: crumbLabel,
  });

  return (
    <header
      id="header"
      className="header-background"
      style={{ backgroundImage: `url(${imagePath})` }}
    >
      <CustomBreadcrumb crumbs={crumbs} />
      <h1 className="header-background__text">{title}</h1>
      <p className="header-background__text">{text}</p>
      <ScrollButton headerElemId='header' className="header-background__btn" />
    </header>
  );
}
