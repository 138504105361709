import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { GatsbyImage, getImage } from "gatsby-plugin-image";


import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";

import Content from "../../components/content/content";
import HeaderBackground from "../../components/header/headerBackground/headerBackground";
import BoxContent from "../../components/boxContent/boxContent";
import SeeMore from "../../components/seeMore/seeMore";

import "./style.scss";

import bgImageDefault from "../../images/produit/default-product-bg-img.webp";
import BlocCasPratique from "../../components/dynamicZone/blocCasPratique/blocCasPratique";
import BlocMentionLegales from "../../components/blocMentionLegales/blocMentionLegales";
const ProductPage = ({ location, data }) => {
  const product = data.strapiProduit;

  let image
  if (product.Section) {
    image = getImage(product.Section.Image?.localFile);
  }

  const products = data.allStrapiProduit.nodes;
  const productsWithSameType = product.Type_Produit
    ? products.filter(
        (p) =>
          p.Type_Produit?.Nom === product.Type_Produit.Nom &&
          p.id !== product.id
      )
    : [];
  return (
    <>
      <Helmet>
        <body className="product" />
      </Helmet>
      <Layout>
        <SEO title={product.Titre} description={product.Description} />
        <HeaderBackground
          location={location}
          title={product.Titre}
          text={product.Description}
          imagePath={
            product.Image_De_Couverture
              ? product.Image_De_Couverture.localFile.publicURL
              : bgImageDefault
          }
          crumbLabel={product.Titre}
        />
        {product.Section && (
          <section className={`product__section1 ${image ? 'product__section1_image' : ''}`}>
            <h2>
              <ReactMarkdown children={product.Section.Titre} />
            </h2>
            <div className="product__box">
              <div className="product__box__items">
                {product.Section.Parties.map((box, i) => {
                  return (
                    <BoxContent key={i}>
                      <h3>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          remarkPlugins={[remarkGfm]}
                          children={box.Titre} 
                        />
                      </h3>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        remarkPlugins={[remarkGfm]}
                        children={box.Contenu.data.Contenu} 
                       />
                    </BoxContent>
                  );
                })}
              </div>
              <GatsbyImage
                className="product__section1-image"
                image={image}
                alt="photo section 1"
              />
            </div>
          </section>
        )}
        {product.Contenu && <Content content={product.Contenu} location={location}/>}
        {product.Cas_Pratique && ( <BlocCasPratique cas_pratique={product.Cas_Pratique} /> )}
        {product.Mentions_Legales?.data?.Mentions_Legales && ( <BlocMentionLegales texte={product.Mentions_Legales.data.Mentions_Legales} /> )}
        <SeeMore
          title="Vos contrats complémentaires"
          btnText="Tous nos contrats"
          btnHref="/assurances"
          products={
            productsWithSameType.length > 0
              ? productsWithSameType
              : products.filter((p) => p.id !== product.id)
          }
          showDate={false}
        />
      </Layout>
    </>
  );
};

export const query = graphql`
  query ProductPageQuery($id: String!) {
    strapiProduit(id: { eq: $id }) {
      id
      Titre
      Description
      Image_De_Couverture {
        localFile {
          publicURL
        }
      }
      Type_Produit {
        Nom
      }
      Section {
        Titre
        Parties {
          Contenu {
            data {
              Contenu
            }
          }
          Titre
        }
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
      Contenu {
        __typename
        ... on STRAPI__COMPONENT_BLOCS_BLOC {
          id
          Fond_De_Couleur
          Couleur_de_fond
          Position_De_L_Image
          Titre
          strapi_component
          Contenu {
            data {
              Contenu
            }
          }
          Images {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
          }          
          Bouton
          Lien
        }
        ... on STRAPI__COMPONENT_BLOCS_BOUTON {
          id
          Texte_str: Texte
          Url
          strapi_component
          Document {
            localFile {
                publicURL
                name
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
            }
          }
        }
        ... on STRAPI__COMPONENT_BLOCS_CAS_PRATIQUE {
          id
          Citation {
            data {
              Citation
            }
          }
          Texte_Bouton
          Texte_Court {
            data {
              Texte_Court
            }
          }
          Texte_Long {
            data {
              Texte_Long
            }
          }
          Titre
          Url_Bouton
          strapi_component
        }
        ... on STRAPI__COMPONENT_BLOCS_CITATION {
          id
          Texte {
            data {
              Texte
            }
          }
          strapi_component
        }
        ... on STRAPI__COMPONENT_BLOCS_DOUBLE_IMAGES {
          id
          strapi_component
          Image1 {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
          Image2 {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_BLOCS_IMAGE_CENTRE {
          id
          full_width
          Image {
            localFile {
              publicURL
            }
          }
          strapi_id
          strapi_component
        }
        ... on STRAPI__COMPONENT_BLOCS_IMAGE {
          id
          Image {
            localFile {
              publicURL
            }
          }
          Lien_Bouton
          Texte_str: Texte
          Texte_Bouton
          Document {
            localFile {
                publicURL
            }
          }
          Titre
          Fil_ariane
          strapi_component
        }
        ... on STRAPI__COMPONENT_BLOCS_FAQ {
          id
          Question
          Reponse {
            data {
                Reponse
            }
          }
          strapi_id
          strapi_component
        }
        ... on STRAPI__COMPONENT_BLOCS_SPACER {
          id
          strapi_id
          strapi_component
          Couleur
          Espace
        }
        ... on STRAPI__COMPONENT_BLOCS_TITRE {
          id
          strapi_id
          strapi_component
          Couleur_de_fond
          Titre
        }
        ... on STRAPI__COMPONENT_BLOCS_ACTION {
          id
          strapi_id
          strapi_component
          Couleur_de_fond
          Actions {
            id
            Titre
            Description {
                data {
                    Description
                }
            }
          }
        }
        ... on STRAPI__COMPONENT_BLOCS_SERVICE {
          id
          strapi_id
          strapi_component
          Couleur_de_fond
          Services {
            id
            Titre
            Bouton
            Lien
            Categorie
            Description {
              data {
                Description
              }
            }
            Image {
              localFile {
                publicURL
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_BLOCS_PARTENAIRE {
          id
          strapi_id
          strapi_component
          Couleur_de_fond
          Partenaires {
            id
            Nom
            Description
            Logo {
              localFile {
                publicURL
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_BLOCS_TEXTE {
          id
          strapi_id
          strapi_component
          Couleur_de_fond
          Texte {
            data {
              Texte
            }
          }
        }
        ... on STRAPI__COMPONENT_BLOCS_CAROUSEL {
          id
          strapi_id
          strapi_component
          Couleur_de_fond
          Titre
          Images {
            localFile {
              publicURL
            }
          }
        }
      }
      Cas_Pratique {
        Citation {
          data {
            Citation
          }
        }
        Texte_Long {
          data {
            Texte_Long
          }
        }
        Texte_Court {
          data {
            Texte_Court
          }
        }
        Image1 {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
        Image2 {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
        Url_Bouton
        Texte_Bouton
        Titre
      }
      Mentions_Legales {
        data {
          Mentions_Legales
        }
      }
    }

    allStrapiProduit(sort: { fields: Date_De_Publication, order: DESC }) {
      nodes {
        id
        Date_De_Publication(formatString: "DD/MM/YYYY")
        Titre
        Slug
        Type_Produit {
          Nom
        }
        Image_Vignette {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
    }
    
  }
`;

export default ProductPage;
