import React from "react";

import "./blocMentionLegales.scss";

import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

export default function BlocCasPratique({texte}) {

  return (
    <section className="mention-legales">
        <div className="mention-legales-texte">
            <ReactMarkdown 
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]} 
                children={texte} 
            />
        </div>
    </section>
  )
}
